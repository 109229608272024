var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    staticClass: "login-form"
  }, [_vm.userRole != 'Client' ? _c('div', {
    staticClass: "form-group mt-1 mb-2"
  }, [[0, 1].includes(_vm.wasiat.status) ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("generate-link-to-testator")) + " "), _c('div', {
    staticClass: "input-group mt-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.generateUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("generate-link")) + " ")])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.Url_Generated,
      expression: "Url_Generated",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": "Pautan untuk dikongsi",
      "aria-label": "Text input with checkbox",
      "id": "testing-code"
    },
    domProps: {
      "value": _vm.Url_Generated
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.Url_Generated = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.copyTestingCode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("copy")) + " ")])], 1)])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa")) + " ")])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa-wasiat-details")) + " ")]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.acknowledged_pdpa,
      expression: "form.acknowledged_pdpa"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "exampleCheck1",
      "value": "1",
      "disabled": _vm.form.acknowledged_pdpa == 1 && _vm.wasiat.status >= 1
    },
    domProps: {
      "checked": Array.isArray(_vm.form.acknowledged_pdpa) ? _vm._i(_vm.form.acknowledged_pdpa, "1") > -1 : _vm.form.acknowledged_pdpa
    },
    on: {
      "click": function ($event) {
        _vm.isShowing_pdpa ^= true;
      },
      "change": function ($event) {
        var $$a = _vm.form.acknowledged_pdpa,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "acknowledged_pdpa", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "acknowledged_pdpa", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "acknowledged_pdpa", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "exampleCheck1"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("understand-pdpa")) + " ")])])])])]), _c('br'), _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(_vm._s(_vm.$t("will-confirmation")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("i")) + ", "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v(", " + _vm._s(_vm.$t("mykad")) + " "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.ic_number)
    }
  })]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.acknowledged_confirmation,
      expression: "form.acknowledged_confirmation"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "exampleCheck2",
      "value": "1",
      "disabled": _vm.form.acknowledged_confirmation == 1 && _vm.wasiat.status >= 1
    },
    domProps: {
      "checked": Array.isArray(_vm.form.acknowledged_confirmation) ? _vm._i(_vm.form.acknowledged_confirmation, "1") > -1 : _vm.form.acknowledged_confirmation
    },
    on: {
      "click": function ($event) {
        _vm.isShowing_confirmation ^= true;
      },
      "change": function ($event) {
        var $$a = _vm.form.acknowledged_confirmation,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "acknowledged_confirmation", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "acknowledged_confirmation", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "acknowledged_confirmation", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "exampleCheck2"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("agree-and-understand")) + ":")])])]), _c('p', [_vm._v(" 1. " + _vm._s(_vm.$t("read-carefully-pds")) + " ")]), _vm.wasiat.price == 1200 ? _c('p', [_vm._v(" 2. " + _vm._s(_vm.$t("appoint-awaris-executor-trustee")) + ":"), _c('br'), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$t("1m-below")))]), _c('li', [_vm._v(_vm._s(_vm.$t("1m-to-2m")))]), _c('li', [_vm._v(_vm._s(_vm.$t("above-2m")))])])]) : _vm._e(), _vm.wasiat.price != 1200 ? _c('p', [_vm._v(" 2. " + _vm._s(_vm.$t("appoint-executor-trustee")) + " ")]) : _vm._e(), _c('p', [_vm._v(" 3. " + _vm._s(_vm.$t("revoke-will")) + " ")]), _vm.wasiatKonvensional ? _c('p', [_vm._v(" 4. " + _vm._s(_vm.$t("equal-shares")) + " ")]) : _vm._e(), _vm.signature ? _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": _vm.signature.public_path,
      "alt": "",
      "srcset": ""
    }
  }) : _vm._e(), !_vm.signature && ![0].includes(this.wasiat.status) ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.$t("signature-has-been-skipped")) + " ")])]) : _vm._e(), _c('div', [_vm._v(" [ "), _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v("] ")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowing_pdpa && _vm.isShowing_confirmation,
      expression: "isShowing_pdpa && isShowing_confirmation"
    }],
    staticClass: "row mt-3"
  }, [!_vm.signature ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "500px",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "500px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()])])])])])]), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group"
  }, [[0, 1, 3].includes(_vm.wasiat.status) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.saveFormNoSignature
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-continue-without-digital-sign")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [[0, 1, 3].includes(_vm.wasiat.status) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.saveForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e(), [2].includes(_vm.wasiat.status) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }